#achievementsPage {

    .achievements-table {
        border: 1px solid #7070704D;

        .at-row {
            display: grid;
            // grid-template-columns: minmax(100px, 200px) repeat(2, minmax(100px, 300px)) minmax(100px, 1fr) auto;
            grid-template-columns: minmax(80px, 100px) repeat(2, minmax(100px, 200px)) minmax(100px, 1fr) minmax(60px, auto);
            gap: 15px;
            align-items: center;

            &:nth-child(odd):not(.bg-black) { background: #F2F2F2; }
        }

        .table-cert {
            padding: 12px;
            background: #F2F2F2;
            border-radius: 5px;

            img {
                width: 15px;
                height: 15px;
                object-fit: contain;
            }
        }
    }
}