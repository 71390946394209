#loginPage {
    background-size: cover;
    background-position: center center;

    .login-wrapper {
        background-color: transparentize($color: #000000, $amount: 0.5);
        padding: 0 250px;

        .login-scroll-control {
            padding: 20px;
            width: 100%;

            .login-box {
                padding: 133px 95px;
                max-width: 851px;
                margin-left: auto;
            }

            a {
                transition: 0.3s;

                &:hover { opacity : 0.5; }
            }
        }
    }
}

@media (max-width: 1024px){
    #loginPage .login-wrapper {
        padding: 0px 150px;
    }
}

@media (max-width: 810px){
    #loginPage .login-wrapper {
        padding: 0px 100px;
    }
}