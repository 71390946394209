#layoutNav {
    border-bottom: 1px solid #D6D6D6;
    background-color: #fff;
    top: 0;
    z-index: 10;

    .search-margin{
        margin: 0 10px;
    }

    .brand-logo {
        max-width: 260px;
        width: 100%;

        > img {
            width: 195px;
            height: 61px;
        }
    }

    .search-wrapper {
        max-width: 477px;
        border: 0.4000000059604645px solid #ccc;
        border-radius: 2px;
        
        input {
            border: none;
            outline: none;
            width: 100%;
        }
    }

    .user-name{
        max-width: 100%;
    }

    @media(max-width: 1024px){
        .user-name{
            display: none;
            margin: 0;
            max-width: 0;
            opacity: 0;
        }
    }

    #userDropdownMenu {
        top: calc(100% + 10px);
        right: -10px;
        background: #fff;
        border: 1px solid #DFDFDF;
        max-width: 260px;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 3px 10px #42424230;
        pointer-events: none;
        opacity: 0;
        transform: translateY(10px);
        transition: 0.3s;

        .dropdown-links-wrapper {
            padding: 20px;
            border-bottom: 1px solid #ccc;
            position: relative;
        }

        .dropdown-link {
            
            i { width: 15px }
        }

        &::after {
            content: '';
            position: absolute;
            pointer-events: none;
            height: 17px;
            width: 17px;
            border-top: 1px solid #DFDFDF;
            border-left: 1px solid #DFDFDF;
            right: 7px;
            top: -8.5px;
            background: #fff;
            border-radius: 5px;
            transform: rotate(45deg);
        }

        &.collapse {
            pointer-events: auto;
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

@media(max-width: 1024px){
    #layoutNav #userDropdownMenu.collapse {
        transform: translate(0px);
    }
    #layoutNav #userDropdownMenu {
        width: 200px;
    }
}