.btn {
    padding: 18px 40px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s;

    &-width-standard { min-width: 240px; }
    &:hover {
        opacity: 0.5;
    }

    &-clear,
    &-icon {
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: 0.2s;
    }

    &-icon {

        .icon {
            transition: 0.2s;
        }

        &:hover {

            .icon {
                transform: scale(1.3);
            }
        }
    }

    &-arrow {
        cursor: pointer;

        .arrow {
            transition: 0.2s;
        }

        &:hover {

            .arrow {
                transform: translateX(10px);
            }
        }
    }

    &-gray { 
        background: #D9D9D9;
        color: #919191;
    }

    &-red { 
        background: $red;
        color: #fff;
    }

    &.disabled { opacity: 0.5; }
}