@import './variables';
@import './button.scss';
@import './flex.scss';
@import './grid.scss';
@import './fonts';
@import './image.scss';
@import './form.scss';

@import './margin.scss';
@import './margin_md.scss';
@import './margin_sm.scss';
@import './margin_xs.scss';

@import './padding.scss';
@import './padding_md.scss';
@import './padding_sm.scss';
@import './padding_xs.scss';

// @import './_slick.scss'; 
// @import './_slick-theme.scss';
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";


body,
html {
    padding: 0;
    margin: 0;
    font-family: $primaryFontRegular;
    font-size: $medium;
    background-color: #F4F4F4;

    div {
        box-sizing: border-box;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.pos {
    
    &-absolute {
        position: absolute;
    }

    &-relative {
        position: relative;
    }

    &-sticky {
        position: sticky;
    }

    &-fixed {
        position: fixed;
    }
}

.d {

    &-block {
        display: block;
    }

    &-inline-block {
        display: inline-block;
    }
}

.of {

    &-hidden {
        overflow: hidden;
    }

    &-auto {
        overflow: auto;
    }
}

.bg {
    &-fff {
        background-color: #fff;
    }

    &-F2F2F2 {
        background-color: #F2F2F2;
    }
    
    &-D9D9D9 {
        background-color: #D9D9D9;
    }

    &-dark-gray {
        background-color: $dGray;
    }

    &-black {
        background-color: #231F20;
    }
}

.br {

    &-5 {
        border-radius: 5px;
    }
}

[role=button] {
    cursor: pointer;
}

.pagination-links {

    .pl-button {
        padding: 5px 10px;
        border: 1px solid #ED1A3A;
        background-color: #fff;
        cursor: pointer;
        transition: 0.2s;

        &:hover { opacity: 0.5; }
    }
}

.loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparentize($color: #fff, $amount: 0.5);
    z-index: 99999;
    
    i {
        font-size: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.skeleton-loader {

    .skeleton-bone {
        border-radius: 10px;
        background-color: #e5e5e5;
        animation-name: skeletonBone;
        animation-iteration-count: infinite;
        animation-duration: 1s;
    }
}

.popup-alert {
    background: $green;
    color: #fff;
    right: 10px;
    bottom: 10px;
    padding: 20px;
    opacity: 0;
    transform: translateY(10px);
    animation-name: popup;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes popup {
    from {
        transform: translateY(10px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes loading {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

@keyframes skeletonBone {
    from, to { transform: scale(1) }
    50% { transform: scale(0.97) }
}