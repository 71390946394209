.flex {
	display: flex;
	
	.flex-1 {
		flex: 1;
    }
    
    .flex-2 {
        flex: 2;
    }

	&.align-center {
		align-items: center;
    }
    
    &.align-end {
		align-items: flex-end;
	}

	&.justify-center {
		justify-content: center;
    }
    
    &.flex-end {
        justify-content: flex-end;
    }

    &.space-evenly {
        justify-content: space-evenly;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.space-around {
        justify-content: space-around;
    }

    &.direction-column {
        flex-direction: column;
        @media (max-width: 426px) {
            &-426 {
                flex-direction: column;
            }
        }
    }

    &-reverse {
        flex-direction: row-reverse;
    }

    &-column-reverse {
        flex-direction: column-reverse;
    }

    &.flex-wrap {
        flex-wrap: wrap;
    }
}

.flex-flow {
    &-column {
        flex-flow: column;
    }
}
.align-items {
    &-center {
        align-items: center;
    }
}
.justify-content {
    &-end {
        justify-content: flex-end;
    }
    &-around {
        justify-content: space-around;
    }
}
.align-all {
    &-center {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 991px) {

    .flex {

        &-rm-md {
            display: block;
        }
    }

    &.align-md-center {
        align-items: center;
    }
    
    &.align-md-end {
        align-items: flex-end;
    }

    &.justify-md-center {
        justify-content: center;
    }
    
    &.flex-md-end {
        justify-content: flex-end;
    }

    &.space-md-evenly {
        justify-content: space-evenly;
    }

    &.space-md-between {
        justify-content: space-between;
    }

    &.space-md-around {
        justify-content: space-around;
    }

    &.flex-md-wrap {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {

    .flex {

        &-rm-xs {
            display: block;
        }
    }

    &.align-xs-center {
        align-items: center;
    }
    
    &.align-xs-end {
        align-items: flex-end;
    }

    &.justify-xs-center {
        justify-content: center;
    }
    
    &.flex-xs-end {
        justify-content: flex-end;
    }

    &.space-xs-evenly {
        justify-content: space-evenly;
    }

    &.space-xs-between {
        justify-content: space-between;
    }

    &.space-xs-around {
        justify-content: space-around;
    }

    &.direction-xs-column {
        flex-direction: column;

        &-reverse {
            flex-direction: column-reverse;
        }
    }

    &.flex-xs-wrap {
        flex-wrap: wrap;
    }
}

@media (max-width: 500px) {

    .flex {

        &-rm-xxs {
            display: block;
        }

        &-xxs-1 {
            flex: 1;
        }
    }

    &.align-xxs-center {
        align-items: center;
    }
    
    &.align-xxs-end {
        align-items: flex-end;
    }

    &.justify-xxs-center {
        justify-content: center;
    }
    
    &.flex-xxs-end {
        justify-content: flex-end;
    }

    &.space-xxs-evenly {
        justify-content: space-evenly;
    }

    &.space-xxs-between {
        justify-content: space-between;
    }

    &.space-xxs-around {
        justify-content: space-around;
    }

    &.direction-xxs-column {
        flex-direction: column;

        &-reverse {
            flex-direction: column-reverse;
        }
    }

    &.flex-xxs-wrap {
        flex-wrap: wrap;
    }
}