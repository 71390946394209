#faqsPage {

    .faqs-box {
        max-width: 1020px;
        width: 100%;
        margin: 110px auto;
        box-shadow: 0px 3px 6px #A5A5A529;
        background: #fff;
        
        .faqs-banner {
            background-size: cover;
            background-position: center center;
            background-color: #666;

            > div {
                font-size: 50px;
                padding: 70px 20px;
                background: rgba(0, 0, 0, 0.7);

                .faqs-underline {
                    max-width: 494px;
                    height: 5px;
                    background: #fff;
                    margin: 15px auto 0;
                }
            }
        }

        .faq-question {
            border-bottom: 1px solid transparentize($color: #707070, $amount: 0.7);
        }
    }
}