@import '@assets/scss/variables';
#coursePage {

    .course-active-module {
        height: 560px;
        position: relative;

        .module-video-wrapper,
        .module-video-wrapper > div {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
            iframe {
                width: 100%;
                height: 100%;
            }
        }
        .module-overlay {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.45);
            cursor: none;
            display: none;
            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) { backdrop-filter: blur(5px); }
        }
    }

    .audio-active-module{
        height: 50px !important;
        .audio-grid{
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 1fr 150px;
            .audio-player{
                width: 100% !important;
            }
        }
    }

    .images-active-module{
        height: unset !important;
    }

    .slider-container{
        position: relative;
        // .slick-track{
        //     width: 100% !important;
        //     // transform: unset !important;
        // }
        // .slick-slide{
        //     // width: 100% !important;
        //     height: 100% !important;
        //     img{
        //         width: 100%;
        //         height: 100%;
        //         object-fit: contain;
        //         object-position: center;
        //     }
        // }
        .carousel{
            .thumbs{
                text-align: center;
            }
        }
        // .slick-slide{
        //     width: 100%;
        //     max-height: 100%;
        //     object-fit: contain;
        //     object-position: center;
        // }
    }

    .course-resource {
        background: #F2F2F2;
        border: 1px solid #7070703B;

        .resource-icon {
            width: 25px;

            img {
                width: 25px;
                height: 25px;
                object-fit: contain;
            }
        }
    }

    .course-module-list {
        max-height: 560px;
        border: 0.5px solid #707070;

        &-header { background: #020504;}

        .course-module {
            transition: 0.3s;

            .resource-duration { color: #919191 }

            &.done {
                background-color: #2C2C2C;
                color: #fff !important;

                i { color: $violet }
            }

            &:not(:last-child) { border-bottom: 0.5px solid #707070; }
            &:hover { background: #ccc }
            &.active { 
                background: #ED1A3A;
                
                .resource-duration { color: #fff }
            }
        }
    }

    .post-exam-module,
    .exam-card {
        background-color: #F2F2F2;
        border: 1px solid #DBDBDB80;
        border-radius: 5px;
    }

    .exam-qa-wrapper {
        max-width: 1024px;
        width: 100%;
        margin: auto;

        .exam-a-card {
            background: #FFFFFF;
            border: 1px solid #70707080;
            border-radius: 3px;
            transition: 0.3s;

            img { max-width: 100% }
            p {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
             }

            &:hover { opacity: 0.5 }
            &.active {
                background-color: $red;
                color: #fff;
            }
        }
    }

    .exam-progress-bar {
        background: #D6D6D6;
        height: 7px;

        > div {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $red;
            transition: 0.3s;
        }
    }

    .exam-progress-count { width: 55px }

    .exam-ctas button:hover { box-shadow: 0px 3px 10px #00000029; }
}

.exam-result-card {
    background-color: #fff;
    max-width: 655px;
    width: 100%;
    box-shadow: 0 10px 20px -15px rgba(0, 0, 0, 0.4);

    img { max-width: 170px; }
}