.txt {
	// SIZE
	&-giantsize {
		font-size: $giantSize;
	}

	&-oversized {
		font-size: $oversized;
	}

	&-header {
		font-size: $h1;

		&-2 { font-size: $h2; }

		&-3 { font-size: $h3 }
	}
	&-large {
		font-size: $large;
	}
	&-large-2 {
		font-size: $large2;
	}
	&-xlarge {
		font-size: $xlarge;
	}
	&-xxlarge {
		font-size: $xxlarge;
	}
	&-xxxlarge {
		font-size: $xxxlarge;
	}
	&-medium {
		font-size: $medium;

		&-large {
			font-size: $mediumLarge;
		}
	}
	&-small {
		font-size: $small !important;
	}
	&-xsmall {
		font-size: 11px;
	}
	&-h1 {
		font-size: $h1;
	}
	&-16 {
		font-size: 16px;
	}
	&-20 {
		font-size: 20px;
	}
	&-22 {
		font-size: 22px;
	}
	&-30 {
		font-size: 30px;
	}

	// COLOR
	&-primary {
		color: $blue !important;
	}

	&-error {
		color: $red !important;

		&-pastel {
			color: $pastelRed !important;
		}
	}

	&-success {
		color: $green !important;

		&-dark {
			color: $dGreen !important;
		}
	}

	&-warning {
		color: $orange !important;
	}

	&-yellow {
		color: $yellow !important;
	}

	&-l-yellow {
		color: $lYellow !important;
	}

	&-gray {
		color: $gray;

		&-med {
			color: $medGray;
		}

		&-light {
			color: $lightGray;
		}

		&-dark {
			color: $dGray;
		}
	}

	&-white {
		color: #fff !important;
	}

	&-violet {
		color: $violet !important;
	}

	&-dark {
		color: $black;
	}

	&-link {
		color: #356077;
	}

	&-dark-link {
		color: $darkBlue;
	}

	&-919191{
		color: #919191;
	}

	// WEIGHT
	&-italic {
		font-family: $primaryFontItalic;
	}

	&-bold {
		font-family: $primaryFontBold;

		&-italic {
			font-family: $primaryFontBoldItalic;
		}

		&-extra {
			font-family: $primaryFontExtraBold;
		}

		&-semi {
			font-family: $primaryFontSemiBold;

			&-italic {
				font-family: $primaryFontSemiBoldItalic;
			}
		}
	}

	&-black {
		font-family: $primaryFontBlack;

		&-italic {
			font-family: $primaryFontBlackItalic;
		}
	}

	&-light {
		font-family: $primaryFontLight;

		&-italic {
			font-family: $primaryFontLightItalic;
		}
	}

	&-regular {
		font-family: $primaryFontRegular !important;
	}

	&-medium-w {
		font-family: $primaryFontMedium !important;
	}

	//SECONDARY FONTS
	&-secondary {
		&-italic {
			font-family: $secondaryFontItalic;
		}
	
		&-bold {
			font-family: $secondaryFontBold;
	
			&-italic {
				font-family: $secondaryFontBoldItalic;
			}
	
			&-extra {
				font-family: $secondaryFontExtraBold;
			}
	
			&-semi {
				font-family: $secondaryFontSemiBold;
	
				&-italic {
					font-family: $secondaryFontSemiBoldItalic;
				}
			}
		}
	
		&-black {
			font-family: $secondaryFontBlack;
	
			&-italic {
				font-family: $secondaryFontBlackItalic;
			}
		}
	
		&-light {
			font-family: $secondaryFontLight;
	
			&-italic {
				font-family: $secondaryFontLightItalic;
			}
		}
	
		&-regular {
			font-family: $secondaryFontRegular !important;
		}
	
		&-medium-w {
			font-family: $secondaryFontMedium !important;
		}
	}

	//SETTINGS

	&-nowrap {
		white-space: nowrap;
	}

	&-ellipsis {
		text-overflow: ellipsis;
	}

	&-uppercase {
		text-transform: uppercase;
	}

	&-capitalize {
		text-transform: capitalize;
	}

	&-underlined {
		text-decoration: underline;
	}

	&-strike {
		text-decoration: line-through;

		&-element {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				background-color: $black;
				margin: auto 0;
			}
		}
	}

	&-lineheight-1 {
		line-height: 1;
	}

	//ALIGNMENT
	&-center {
		text-align: center;
	}

	&-right {
		text-align: right;
	}

	&-justify {
		text-align: justify;
	}

	@media (max-width: 1240px) {
		&-giantsize {
			font-size: $oversized;
		}
		&-oversized {
			font-size: $h1;
		}
		&-header {
			font-size: $h2;
	
			&-2 {
				font-size: $xxlarge;
			}
		}
		
		&-large {
			font-size: $medium;
		}
		&-xlarge {
			font-size: $large;
		}
		&-xxlarge {
			font-size: $xlarge;
		}
		&-xxlarge {
			font-size: $xxlarge;
		}
		&-medium {
			font-size: $small;
		}
	}

	@media (max-width: 767px) {
		&-giantsize {
			font-size: $h1;
		}
		&-oversized {
			font-size: $h2;
		}
		&-header {
			font-size: $xxlarge;
	
			&-2 {
				font-size: $xlarge;
			}
		}

		&-medium {
			font-size: $small;

			&-large {
				font-size: $medium;
			}
		}

		&-large {
			font-size: $medium;
		}

		&-xlarge {
			font-size: $medium;
		}

		&-xxlarge {
			font-size: $large;
		}

		&-xlarge {
			font-size: $xlarge;
		}

		&-small {
			font-size: 10px;
		}
	}
}

.badge {
	border-radius: 100px;
	padding: 5px 10px;
	display: inline-block;
	
	&-primary {
		background: $blue;
		color: #fff;
	}

	&-warning {
		background: $orange;
		color: #fff;
	}

	&-success {
		background: $green;
		color: #fff;
	}

	&-error {
		background: $red;
		color: #fff;
	}

	&-white {
		background: #fff;
		color: #111 !important;
	}
}

@media (max-width: 991px) {
	.txt-md {
		// SIZE
		&-giantsize {
			font-size: $giantSize;
		}
	
		&-oversized {
			font-size: $oversized;
		}
	
		&-header {
			font-size: $h1;
	
			&-2 {
				font-size: $h2;
			}
		}
		&-large {
			font-size: $large;
		}
		&-xlarge {
			font-size: $xlarge;
		}
		&-xxlarge {
			font-size: $xxlarge;
		}
		&-medium {
			font-size: $medium;
	
			&-large {
				font-size: $mediumLarge;
			}
		}
		&-small {
			font-size: $small !important;
		}
		&-xsmall {
			font-size: 11px;
		}
	
		//ALIGNMENT
		&-center {
			text-align: center;
		}
	
		&-right {
			text-align: right;
		}
	
		&-justify {
			text-align: justify;
		}
	}
}

@media (max-width: 767px) {
	.txt-xs {
		// SIZE
		&-giantsize {
			font-size: $giantSize;
		}
	
		&-oversized {
			font-size: $oversized;
		}
	
		&-header {
			font-size: $h1;
	
			&-2 {
				font-size: $h2;
			}
		}
		&-large {
			font-size: $large;
		}
		&-xlarge {
			font-size: $xlarge;
		}
		&-xxlarge {
			font-size: $xxlarge;
		}
		&-medium {
			font-size: $medium;
	
			&-large {
				font-size: $mediumLarge;
			}
		}
		&-small {
			font-size: $small !important;
		}
		&-xsmall {
			font-size: 11px;
		}
	
		//ALIGNMENT
		&-center {
			text-align: center;
		}
	
		&-right {
			text-align: right;
		}
	
		&-justify {
			text-align: justify;
		}
	}
}