.p {

    &-5 {
        padding: 5px;
    }

    &-10 {
        padding: 10px !important;
    }

    &-15 {
        padding: 15px !important;
    }

    &-20 {
        padding: 20px !important;
    }

    &-25 {
        padding: 25px !important;
    }
    
    &-30 {
        padding: 30px !important;
    }

    &-35 {
        padding: 35px !important;
    }

    &-40 {
        padding: 40px !important;
    }

    &-45 {
        padding: 45px !important;
    }

    &-50 {
        padding: 50px !important;
    }
}

.pt {

    &-5 {
        padding-top: 5px;
    }

    &-10 {
        padding-top: 10px !important;
    }

    &-15 {
        padding-top: 15px !important;
    }

    &-20 {
        padding-top: 20px !important;
    }

    &-25 {
        padding-top: 25px !important;
    }
    
    &-30 {
        padding-top: 30px !important;
    }

    &-35 {
        padding-top: 35px !important;
    }

    &-40 {
        padding-top: 40px !important;
    }

    &-45 {
        padding-top: 45px !important;
    }

    &-50 {
        padding-top: 50px !important;
    }
}

.pb {

    &-5 {
        padding-bottom: 5px;
    }

    &-10 {
        padding-bottom: 10px !important;
    }

    &-15 {
        padding-bottom: 15px !important;
    }

    &-20 {
        padding-bottom: 20px !important;
    }

    &-25 {
        padding-bottom: 25px !important;
    }
    
    &-30 {
        padding-bottom: 30px !important;
    }

    &-35 {
        padding-bottom: 35px !important;
    }

    &-40 {
        padding-bottom: 40px !important;
    }

    &-45 {
        padding-bottom: 45px !important;
    }

    &-50 {
        padding-bottom: 50px !important;
    }
}

.pl {

    &-5 {
        padding-left: 5px;
    }

    &-10 {
        padding-left: 10px !important;
    }

    &-15 {
        padding-left: 15px !important;
    }

    &-20 {
        padding-left: 20px !important;
    }

    &-25 {
        padding-left: 25px !important;
    }
    
    &-30 {
        padding-left: 30px !important;
    }

    &-35 {
        padding-left: 35px !important;
    }

    &-40 {
        padding-left: 40px !important;
    }

    &-45 {
        padding-left: 45px !important;
    }

    &-50 {
        padding-left: 50px !important;
    }
}

.pr {

    &-5 {
        padding-right: 5px;
    }

    &-10 {
        padding-right: 10px !important;
    }

    &-15 {
        padding-right: 15px !important;
    }

    &-20 {
        padding-right: 20px !important;
    }

    &-25 {
        padding-right: 25px !important;
    }
    
    &-30 {
        padding-right: 30px !important;
    }

    &-35 {
        padding-right: 35px !important;
    }

    &-40 {
        padding-right: 40px !important;
    }

    &-45 {
        padding-right: 45px !important;
    }

    &-50 {
        padding-right: 50px !important;
    }
}

.py {

    &-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &-10 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    &-15 {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    &-20 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    &-25 {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
    
    &-30 {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    &-35 {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    &-40 {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    &-45 {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    &-50 {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
}

.px {

    &-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    &-10 {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    &-15 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    &-20 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    &-25 {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    
    &-30 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    &-35 {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    &-40 {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    &-45 {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    &-50 {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}