#coursesPage {

    .course-filters {

        .filter-indicator {
            height: 5px;
            width: 100%;
            background-color: #ED1A3A;
            transition: 0.3s;
        }
    }

    .course-card,
    .task-card {
        background: #231F20;
        border: 0.5px solid #707070;

        .course-image {
            width: 350px;
            height: 210px;
            object-fit: cover;
        }

        .task-card-progress {
            height: 8px;
            background: #fff;
            border-radius: 100px;
            overflow: hidden;
            border: 1px solid #231F20;

            .indicator {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .course-progress-bar {
            background: #D6D6D6;
            height: 7px;
    
            > div {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: #ED1A3A;
                transition: 0.3s;
            }
        }

        .course-progress-count { width: 135px; }
    }

    .tasks-summary {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        &-header { background: #231F20 }

        .task-dot {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #ED1A3A;
        }
    }
}

@media (max-width: 1600px){
    .courses-grid {
        grid-template-columns: 2fr minmax(450px, 1fr) !important;
    }
    #coursesPage .course-card .course-image, #coursesPage .task-card .course-image {
        width: 270px;
        height: 200px;
    }
    .courses-inner-grid {
        grid-template-columns: 2fr minmax(450px, 1fr) !important;
    }
}
@media (max-width: 1550px){
    #coursesPage .course-card, #coursesPage .task-card {
        flex-flow: column;
    }
    #coursesPage .course-card .course-image, #coursesPage .task-card .course-image {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
    }
    .courses-inner-grid {
        grid-template-columns: 2fr minmax(400px, 1fr) !important;
    }
}
@media (max-width: 1500px){
    #coursesPage .course-card, #coursesPage .task-card {
        flex-flow: column;
    }
    #coursesPage .course-card .course-image, #coursesPage .task-card .course-image {
        width: 100%;
        height: 200px;
    }
    .courses-inner-grid {
        grid-template-columns: 1fr !important;
        & > div:first-child{
            order: 2;
        }
        & > div:last-child{
            order: 1;
        }
    }
}
@media (max-width: 1240px){
    .courses-grid {
        grid-template-columns: 2fr minmax(350px, 1fr) !important;
    }
    // .courses-inner-grid {
    //     grid-template-columns: 2fr minmax(350px, 1fr) !important;
    // }
}
@media (max-width: 1180px){
    .courses-grid {
        grid-template-columns: 1fr !important;
    }
    #coursesPage .course-card, #coursesPage .task-card {
        flex-flow: unset;
    }
    #coursesPage .course-card .course-image, #coursesPage .task-card .course-image {
        width: 350px;
        height: 210px;
    }
}