// PRIMARY FONTS
@font-face {
	font-family: 'font-black';
	src: url('../fonts/primary/SourceSansPro-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'font-black-italic';
	src: url('../fonts/primary/SourceSansPro-BlackItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-bold';
	src: url('../fonts/primary/SourceSansPro-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-bold-italic';
	src: url('../fonts/primary/SourceSansPro-BoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-extra-bold';
	src: url('../fonts/primary/SourceSansPro-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-semi-bold';
	src: url('../fonts/primary/SourceSansPro-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-extra-light';
	src: url('../fonts/primary/SourceSansPro-ExtraLight.ttf') format('truetype');
}
@font-face {
	font-family: 'font-italic';
	src: url('../fonts/primary/SourceSansPro-Italic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-light';
	src: url('../fonts/primary/SourceSansPro-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'font-light-italic';
	src: url('../fonts/primary/SourceSansPro-LightItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-regular';
	src: url('../fonts/primary/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'font-medium';
	src: url('../fonts/primary/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'font-semi-bold-italic';
	src: url('../fonts/primary/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

//SECONDARY FONTS
@font-face {
	font-family: 'font-secondary-black';
	src: url('../fonts/secondary/Roboto-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-black-italic';
	src: url('../fonts/secondary/Roboto-BlackItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-bold';
	src: url('../fonts/secondary/Roboto-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-bold-italic';
	src: url('../fonts/secondary/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-extra-bold';
	src: url('../fonts/secondary/Roboto-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-semi-bold';
	src: url('../fonts/secondary/Roboto-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-extra-light';
	src: url('../fonts/secondary/Roboto-Thin.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-italic';
	src: url('../fonts/secondary/Roboto-Italic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-light';
	src: url('../fonts/secondary/Roboto-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-light-italic';
	src: url('../fonts/secondary/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-regular';
	src: url('../fonts/secondary/Roboto-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-medium';
	src: url('../fonts/secondary/Roboto-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'font-secondary-semi-bold-italic';
	src: url('../fonts/secondary/Roboto-BoldItalic.ttf') format('truetype');
}

$dirtyWhite: #F8F8F8;
$blue: #22afd6;
$lightBlue: #2BD5FF;
$pastelBlue: #64c1dd;
$darkBlue: #2B5059;
$placeholderBg: #E5E5E5;

$green: #2dcfa6;
$lGreen: #80E3BE;
$dGreen: #435940;
$pastelGreen: #cae8d2;
$black: #626366;
$dGray: #231F20;
$gray: #707070;
$medGray: #D6D6D6;
$lightGray: #F4F4F4;
$xLightGray: #EDEDED;
$orange: #f5cd5d;
$pastelOrange: #FFF2D0;
$pastelOrangeDark: #FBB381;
$red: #ED1A3A;
$darkRed: #801223;
$pastelRed: #FF838C;
$violet: #9B5BA5;
$yellow: #f5cd5d;
$lightYellow: #FFF07C;
$lYellow: #F4EDDD;
$transition: .6s;
$transitionFast: .3s;
$navTopSpacing: 120px;
$navTopMobileSpacing: 140px;
$defaultGradient: linear-gradient(90deg, #E85523, #F47A21, #F19F19);


// FONT SIZES
$giantSize: 120px;
$oversized: 60px;
$h1: 50px;
$h2: 35px;
$h3: 30px;
$small: 12px;
$medium: 15px;
$mediumLarge: 16px;
$large: 18px;
$large2: 19px;
$xlarge: 21px;
$xxlarge: 25px;
$xxxlarge: 28px;

// FONT FACES
$primaryFontBlack: 'font-black';
$primaryFontBlackItalic: 'font-black-italic';
$primaryFontBold: 'font-bold';
$primaryFontBoldItalic: 'font-bold-italic';
$primaryFontExtraBold: 'font-extra-bold';
$primaryFontExtraLight: 'font-extra-light';
$primaryFontExtraLightItalic: 'font-extra-light-italic';
$primaryFontItalic: 'font-italic';
$primaryFontLight: 'font-light';
$primaryFontLightItalic: 'font-light-italic';
$primaryFontRegular: 'font-regular';
$primaryFontMedium: 'font-medium';
$primaryFontSemiBold: 'font-semi-bold';
$primaryFontSemiBoldItalic: 'font-semi-bold-italic';

$secondaryFontBlack: 'font-secondary-black';
$secondaryFontBlackItalic: 'font-secondary-black-italic';
$secondaryFontBold: 'font-secondary-bold';
$secondaryFontBoldItalic: 'font-secondary-bold-italic';
$secondaryFontExtraBold: 'font-secondary-extra-bold';
$secondaryFontExtraLight: 'font-secondary-extra-light';
$secondaryFontExtraLightItalic: 'font-secondary-extra-light-italic';
$secondaryFontItalic: 'font-secondary-italic';
$secondaryFontLight: 'font-secondary-light';
$secondaryFontLightItalic: 'font-secondary-light-italic';
$secondaryFontRegular: 'font-secondary-regular';
$secondaryFontMedium: 'font-secondary-medium';
$secondaryFontSemiBold: 'font-secondary-semi-bold';
$secondaryFontSemiBoldItalic: 'font-secondary-semi-bold-italic';
//bootstrap variables
$theme-colors: (
  "primary": $green,
  "secondary": $orange
);