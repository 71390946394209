.form-input {
    border: 1px solid #70707070;
    border-radius: 5px;

    input {
        border: none;
        padding: 16px;
        outline: none;
        background: transparent;
        font-size: 16px;
    }

    .form-input-button {
        background: transparent;
        border: none;
        padding: 0 16px;
    }

    .form-input-label {
        font-size: 16px;
        left: 11px;
        top: 0;
        line-height: 1;
        background: #fff;
        transform: translateY(calc(50% + 8px));
        padding: 0 5px;
        pointer-events: none;
        transition: 0.2s;
    }

    &.active {
        .form-input-label {
            font-size: 12px;
            transform: translateY(-50%);
        }
    }
}

.lms-checkbox {

    .box {
        border: 1px solid #707070;
        height: 20px;
        width: 20px;
        position: relative;

        i {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000;
            transition: 0.1s;
            transform: scale(0);
        }

        &.is-checked {

            i { transform: scale(1); }
        }
    }
}