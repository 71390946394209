img,
.user-image-container {
	overflow: hidden;
	display: block;
    outline: none;
    
	&.img-round {
		border-radius: 100%;

		&-corner {
			border-radius: 5px;
		}
	}

	&.img-sqr {
		object-position: center;
	}

	&.img-cover {
		object-fit: cover;
	}

	&.img-contain {
		object-fit: contain;
	}

	&.img {
		max-width: 100%;
		width: 100%;

		&-large {
			max-width: 700px;
			width: 700px;

			&.img-sqr {
				height: 700px;
				max-height: 700px;
			}
		}

		&-medium {
			max-width: 300px;
			width: 300px;

			&.img-sqr {
				height: 300px;
				max-height: 300px;
			}

			&-large {
				max-width: 400px;
				width: 400px;

				&.img-sqr {
					height: 400px;
					max-height: 400px;
				}
			}
		}

		&-small {
			max-width: 200px;
			width: 200px;

			&.img-sqr {
				height: 200px;
				max-height: 200px;
			}
		}

		&-xsmall {
			max-width: 150px;
			width: 150px;

			&.img-sqr {
				height: 150px;
				max-height: 150px;
			}
		}

		&-thumb {
			max-width: 100px;
			width: 100px;

			&.img-sqr {
				height: 100px;
				max-height: 100px;
			}

			&-large {
				max-width: 70px;
				width: 70px;

				&.img-sqr {
					height: 70px;
					max-height: 70px;
				}
			}

			&-medium {
				max-width: 50px;
				width: 50px;

				&.img-sqr {
					height: 50px;
					max-height: 50px;
				}
			}

			&-small {
				max-width: 30px;
				width: 30px;

				&.img-sqr {
					height: 30px;
					max-height: 30px;
				}
			}

			&-xsmall {
				max-width: 25px;
				width: 25px;

				&.img-sqr {
					height: 25px;
					max-height: 25px;
				}
			}
		}
	}
}

@media (max-width: 991px) {
    img,
    .user-image-container {
        overflow: hidden;
        display: block;

        &.img-md-round {
            border-radius: 100%;

            &-corner {
                border-radius: 5px;
            }
        }

        &.img-md-sqr {
            object-position: center;
        }

        &.img-md-cover {
            object-fit: cover;
        }

        &.img-md-contain {
            object-fit: contain;
        }

        &.img-md {
            max-width: 100%;
            width: 100%;

            &-large {
                max-width: 700px;
                width: 700px;

                &.img-sqr {
                    height: 700px;
                    max-height: 700px;
                }
            }

            &-medium {
                max-width: 300px;
                width: 300px;

                &.img-sqr {
                    height: 300px;
                    max-height: 300px;
                }

                &-large {
                    max-width: 400px;
                    width: 400px;

                    &.img-sqr {
                        height: 400px;
                        max-height: 400px;
                    }
                }
            }

            &-small {
                max-width: 200px;
                width: 200px;

                &.img-sqr {
                    height: 200px;
                    max-height: 200px;
                }
            }

            &-xsmall {
                max-width: 150px;
                width: 150px;

                &.img-sqr {
                    height: 150px;
                    max-height: 150px;
                }
            }

            &-thumb {
                max-width: 100px;
                width: 100px;

                &.img-sqr {
                    height: 100px;
                    max-height: 100px;
                }

                &-large {
                    max-width: 70px;
                    width: 70px;

                    &.img-sqr {
                        height: 70px;
                        max-height: 70px;
                    }
                }

                &-medium {
                    max-width: 50px;
                    width: 50px;

                    &.img-sqr {
                        height: 50px;
                        max-height: 50px;
                    }
                }

                &-small {
                    max-width: 30px;
                    width: 30px;

                    &.img-sqr {
                        height: 30px;
                        max-height: 30px;
                    }
                }

                &-xsmall {
                    max-width: 25px;
                    width: 25px;

                    &.img-sqr {
                        height: 25px;
                        max-height: 25px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    img,
    .user-image-container {
        overflow: hidden;
        display: block;

        &.img-xs-round {
            border-radius: 100%;

            &-corner {
                border-radius: 5px;
            }
        }

        &.img-xs-sqr {
            object-position: center;
        }

        &.img-xs-cover {
            object-fit: cover;
        }

        &.img-xs-contain {
            object-fit: contain;
        }

        &.img-xs {
            max-width: 100%;
            width: 100%;

            &-large {
                max-width: 700px;
                width: 700px;

                &.img-sqr {
                    height: 700px;
                    max-height: 700px;
                }
            }

            &-medium {
                max-width: 300px;
                width: 300px;

                &.img-sqr {
                    height: 300px;
                    max-height: 300px;
                }

                &-large {
                    max-width: 400px;
                    width: 400px;

                    &.img-sqr {
                        height: 400px;
                        max-height: 400px;
                    }
                }
            }

            &-small {
                max-width: 200px;
                width: 200px;

                &.img-sqr {
                    height: 200px;
                    max-height: 200px;
                }
            }

            &-xsmall {
                max-width: 150px;
                width: 150px;

                &.img-sqr {
                    height: 150px;
                    max-height: 150px;
                }
            }

            &-thumb {
                max-width: 100px;
                width: 100px;

                &.img-sqr {
                    height: 100px;
                    max-height: 100px;
                }

                &-large {
                    max-width: 70px;
                    width: 70px;

                    &.img-sqr {
                        height: 70px;
                        max-height: 70px;
                    }
                }

                &-medium {
                    max-width: 50px;
                    width: 50px;

                    &.img-sqr {
                        height: 50px;
                        max-height: 50px;
                    }
                }

                &-small {
                    max-width: 30px;
                    width: 30px;

                    &.img-sqr {
                        height: 30px;
                        max-height: 30px;
                    }
                }

                &-xsmall {
                    max-width: 25px;
                    width: 25px;

                    &.img-sqr {
                        height: 25px;
                        max-height: 25px;
                    }
                }
            }
        }
    }
}