@media (max-width: 500px) {
    .m-xs {

        &-5 {
            margin: 5px;
        }
    
        &-10 {
            margin: 10px;
        }
    
        &-15 {
            margin: 15px;
        }
    
        &-20 {
            margin: 20px;
        }
    
        &-25 {
            margin: 25px;
        }
        
        &-30 {
            margin: 30px;
        }
    
        &-35 {
            margin: 35px;
        }
    
        &-40 {
            margin: 40px;
        }
    
        &-45 {
            margin: 45px;
        }
    
        &-50 {
            margin: 50px;
        }
    }
    
    .mt-xs {
    
        &-5 {
            margin-top: 5px;
        }
    
        &-10 {
            margin-top: 10px;
        }
    
        &-15 {
            margin-top: 15px;
        }
    
        &-20 {
            margin-top: 20px;
        }
    
        &-25 {
            margin-top: 25px;
        }
        
        &-30 {
            margin-top: 30px;
        }
    
        &-35 {
            margin-top: 35px;
        }
    
        &-40 {
            margin-top: 40px;
        }
    
        &-45 {
            margin-top: 45px;
        }
    
        &-50 {
            margin-top: 50px;
        }
    }
    
    .mb-xs {
    
        &-5 {
            margin-bottom: 5px;
        }
    
        &-10 {
            margin-bottom: 10px;
        }
    
        &-15 {
            margin-bottom: 15px;
        }
    
        &-20 {
            margin-bottom: 20px;
        }
    
        &-25 {
            margin-bottom: 25px;
        }
        
        &-30 {
            margin-bottom: 30px;
        }
    
        &-35 {
            margin-bottom: 35px;
        }
    
        &-40 {
            margin-bottom: 40px;
        }
    
        &-45 {
            margin-bottom: 45px;
        }
    
        &-50 {
            margin-bottom: 50px;
        }
    }
    
    .ml-xs {
    
        &-5 {
            margin-left: 5px;
        }
    
        &-10 {
            margin-left: 10px;
        }
    
        &-15 {
            margin-left: 15px;
        }
    
        &-20 {
            margin-left: 20px;
        }
    
        &-25 {
            margin-left: 25px;
        }
        
        &-30 {
            margin-left: 30px;
        }
    
        &-35 {
            margin-left: 35px;
        }
    
        &-40 {
            margin-left: 40px;
        }
    
        &-45 {
            margin-left: 45px;
        }
    
        &-50 {
            margin-left: 50px;
        }
    }
    
    .mr-xs {
    
        &-5 {
            margin-right: 5px;
        }
    
        &-10 {
            margin-right: 10px;
        }
    
        &-15 {
            margin-right: 15px;
        }
    
        &-20 {
            margin-right: 20px;
        }
    
        &-25 {
            margin-right: 25px;
        }
        
        &-30 {
            margin-right: 30px;
        }
    
        &-35 {
            margin-right: 35px;
        }
    
        &-40 {
            margin-right: 40px;
        }
    
        &-45 {
            margin-right: 45px;
        }
    
        &-50 {
            margin-right: 50px;
        }
    }
    
    .my-xs {
    
        &-5 {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    
        &-10 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        &-15 {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    
        &-20 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    
        &-25 {
            margin-top: 25px;
            margin-bottom: 25px;
        }
        
        &-30 {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    
        &-35 {
            margin-top: 35px;
            margin-bottom: 35px;
        }
    
        &-40 {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    
        &-45 {
            margin-top: 45px;
            margin-bottom: 45px;
        }
    
        &-50 {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    
    .mx-xs {
    
        &-5 {
            margin-left: 5px;
            margin-right: 5px;
        }
    
        &-10 {
            margin-left: 10px;
            margin-right: 10px;
        }
    
        &-15 {
            margin-left: 15px;
            margin-right: 15px;
        }
    
        &-20 {
            margin-left: 20px;
            margin-right: 20px;
        }
    
        &-25 {
            margin-left: 25px;
            margin-right: 25px;
        }
        
        &-30 {
            margin-left: 30px;
            margin-right: 30px;
        }
    
        &-35 {
            margin-left: 35px;
            margin-right: 35px;
        }
    
        &-40 {
            margin-left: 40px;
            margin-right: 40px;
        }
    
        &-45 {
            margin-left: 45px;
            margin-right: 45px;
        }
    
        &-50 {
            margin-left: 50px;
            margin-right: 50px;
        }
    }
}