.grid {
	display: grid;
    grid-template-columns: minmax(1px, 1fr);
    
    &.grid-layout-wrapper {
        grid-template-columns: 2fr minmax(500px, 1fr);
        gap: 50px;
    }

    &.grid-account-wrapper {
        margin-top: 80px;
        grid-template-columns: 340px 1fr;
        gap: 30px;
    }
    
	&.justify-center {
		justify-content: center;
	}

	&.align-center {
		align-items: center;
	}
	
	&-2 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto;
		}

		&-auto-1fr {
			grid-template-columns: auto 1fr;
		}

		&-1fr-auto {
			grid-template-columns: 1fr auto;
		}

		&-1fr-500 {
			grid-template-columns: 1fr 500px;
		}
	}
	
	&-3 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto auto;
		}

		&-1fr-auto-auto {
			grid-template-columns: 1fr auto auto;
		}
		&-1fr-auto-1fr {
			grid-template-columns: minmax(200px, 1fr) minmax(200px, 500px) minmax(200px, 1fr);
		}
	}

	&-4 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-repeat {
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		}

		&-auto {
			grid-template-columns: auto auto auto auto;
		}
	}

	&-5 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto auto auto auto;
		}
	}

	&-6 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto auto auto auto auto;
		}
	}

	&-7 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto auto auto auto auto auto;
		}
	}

	&-8 {
		grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);

		&-auto {
			grid-template-columns: auto auto auto auto auto auto auto auto;
		}
	}

	&-gap-10 {
		gap: 10px;
	}

	&-gap-20 {
		gap: 20px;
	}

    &-gap-25 {
		gap: 25px;
	}

	&-gap-30 {
		gap: 30px;
	}

	&-gap-40 {
		gap: 40px;
	}

	&-gap-50 {
		gap: 50px;
	}
}

@media (max-width: 991px) {
    .grid-md {
        display: grid;
        grid-template-columns: minmax(1px, 1fr);

        &.justify-center {
            justify-content: center;
        }
    
        &.align-center {
            align-items: center;
        }
        
        &-2 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto;
            }
    
            &-auto-1fr {
                grid-template-columns: auto 1fr;
            }
    
            &-1fr-auto {
                grid-template-columns: 1fr auto;
            }
    
            &-1fr-500 {
                grid-template-columns: 1fr 500px;
            }
        }
        
        &-3 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto;
            }
    
            &-1fr-auto-auto {
                grid-template-columns: 1fr auto auto;
            }
            &-1fr-auto-1fr {
                grid-template-columns: minmax(200px, 1fr) minmax(200px, 500px) minmax(200px, 1fr);
            }
        }
    
        &-4 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-repeat {
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            }
    
            &-auto {
                grid-template-columns: auto auto auto auto;
            }
        }
    
        &-5 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto;
            }
        }
    
        &-6 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto;
            }
        }
    
        &-7 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto;
            }
        }
    
        &-8 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto auto;
            }
        }
    
        &-gap-10 {
            gap: 10px;
        }
    
        &-gap-20 {
            gap: 20px;
        }
    
        &-gap-30 {
            gap: 30px;
        }
    
        &-gap-40 {
            gap: 40px;
        }
    
        &-gap-50 {
            gap: 50px;
        }
    }
}

@media (max-width: 767px) {
    .grid-xs {
        display: grid;
        grid-template-columns: minmax(1px, 1fr);

        &.justify-center {
            justify-content: center;
        }
    
        &.align-center {
            align-items: center;
        }
        
        &-2 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto;
            }
    
            &-auto-1fr {
                grid-template-columns: auto 1fr;
            }
    
            &-1fr-auto {
                grid-template-columns: 1fr auto;
            }
    
            &-1fr-500 {
                grid-template-columns: 1fr 500px;
            }
        }
        
        &-3 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto;
            }
    
            &-1fr-auto-auto {
                grid-template-columns: 1fr auto auto;
            }
            &-1fr-auto-1fr {
                grid-template-columns: minmax(200px, 1fr) minmax(200px, 500px) minmax(200px, 1fr);
            }
        }
    
        &-4 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-repeat {
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            }
    
            &-auto {
                grid-template-columns: auto auto auto auto;
            }
        }
    
        &-5 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto;
            }
        }
    
        &-6 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto;
            }
        }
    
        &-7 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto;
            }
        }
    
        &-8 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto auto;
            }
        }
    
        &-gap-10 {
            gap: 10px;
        }
    
        &-gap-20 {
            gap: 20px;
        }
    
        &-gap-30 {
            gap: 30px;
        }
    
        &-gap-40 {
            gap: 40px;
        }
    
        &-gap-50 {
            gap: 50px;
        }
    }
}

@media (max-width: 450px) {
    .grid-xxs {
        display: grid;
        grid-template-columns: minmax(1px, 1fr);

        &.justify-center {
            justify-content: center;
        }
    
        &.align-center {
            align-items: center;
        }
        &-2 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto;
            }
    
            &-auto-1fr {
                grid-template-columns: auto 1fr;
            }
    
            &-1fr-auto {
                grid-template-columns: 1fr auto;
            }
    
            &-1fr-500 {
                grid-template-columns: 1fr 500px;
            }
        }

        &-2 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto;
            }
    
            &-auto-1fr {
                grid-template-columns: auto 1fr;
            }
    
            &-1fr-auto {
                grid-template-columns: 1fr auto;
            }
    
            &-1fr-500 {
                grid-template-columns: 1fr 500px;
            }
        }
        
        &-3 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto;
            }
    
            &-1fr-auto-auto {
                grid-template-columns: 1fr auto auto;
            }
            &-1fr-auto-1fr {
                grid-template-columns: minmax(200px, 1fr) minmax(200px, 500px) minmax(200px, 1fr);
            }
        }
    
        &-4 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-repeat {
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            }
    
            &-auto {
                grid-template-columns: auto auto auto auto;
            }
        }
    
        &-5 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto;
            }
        }
    
        &-6 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto;
            }
        }
    
        &-7 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto;
            }
        }
    
        &-8 {
            grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    
            &-auto {
                grid-template-columns: auto auto auto auto auto auto auto auto;
            }
        }
    
        &-gap-10 {
            gap: 10px;
        }
    
        &-gap-20 {
            gap: 20px;
        }
    
        &-gap-30 {
            gap: 30px;
        }
    
        &-gap-40 {
            gap: 40px;
        }
    
        &-gap-50 {
            gap: 50px;
        }
    }
}