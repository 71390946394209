#layoutSideMenu {
    height: calc(100% - 92px);
    width: 260px;
    left: 0;
    bottom: 0;
    z-index: 1000;
    transition: 0.3s;
    .menu-link {
        color: #fff;
        display: block;

        i {
            width: 20px;
            transition: 0.3s;
        }

        .active-bg-color {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: translateX(-100%);
            transition: 0.5s;

            > div  {
                position: absolute;
                width: 10px;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        &:hover,
        &.active {
            i {
                color: transparentize($color: #000000, $amount: 0.4);
            }

            .active-bg-color {
                transform: translateX(0);
            }
        }
    }
}

#layoutSideMenuMobile {
    height: calc(100% - 92px);
    width: 80px;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
    .bars-mobile{
        display: block;
        height: 100%;
        opacity: 1;
        cursor: pointer;
    }
    .menu-link {
        color: #fff;
        display: block;

        i {
            width: 20px;
            transition: 0.3s;
        }

        .active-bg-color {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: translateX(-100%);
            transition: 0.5s;

            > div  {
                position: absolute;
                width: 10px;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        &:hover,
        &.active {
            i {
                color: transparentize($color: #000000, $amount: 0.4);
            }

            .active-bg-color {
                transform: translateX(0);
            }
        }
    }
    .opacity-0{
        visibility: hidden;
        opacity: 0;
    }
}

@media (max-width: 1024px){
    #layoutSideMenuMobile{
        // display: block;
        opacity: 1;
        z-index: 1000;
        &.hide-menu{
            // display: none !important;
            opacity: 0 !important;
            z-index: 1000;
        }
        &.show-menu{
            // display: block !important;
            opacity: 1 !important;
            z-index: 1;
        }
    }
    #layoutSideMenu{
        // display: none;
        opacity: 0;
        z-index: 1;
        &.hide-menu{
            // display: none !important;
            opacity: 0 !important;
            z-index: -1;
        }
        &.show-menu{
            // display: block !important;
            opacity: 1 !important;
            z-index: 1000;
        }
    }
    
}
