#dashboardPage {

    .dashboard-banner {
        background-size: cover;
        background-position: center center;
        border-radius: 5px;

        > div {
            background: transparentize($color: #020504, $amount: 0.3);
            border: 1px solid #70707066;
        }
    }

    .dashboard-tasks {

        .task-card {

            .task-image {
                width: 179px;
                height: 88px;
                object-fit: cover;
            }

            .task-progress-bar {
                background: #D6D6D6;
                height: 7px;
        
                > div {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: #ED1A3A;
                    transition: 0.3s;
                }
            }

            .task-progress-count { width: 55px }
        }
    }

    @media (max-width: 1700px){
        .dashboard-quicklinks .grid-2{
            grid-template-columns: 1fr;
        }
    }
    
    // @media (max-width: 1300px){
    //     .grid.grid-layout-wrapper {
    //         grid-template-columns: 2fr minmax(300px, 1fr);
    //     }
    // }
}

@media (max-width: 1380px){
    .dashboard-grid {
        grid-template-columns: 2fr minmax(450px, 1fr) !important;
    }
}

@media (max-width: 1240px){
    .dashboard-grid {
        grid-template-columns: 2fr minmax(350px, 1fr) !important;
    }
}

@media (max-width: 1180px){
    .dashboard-grid {
        grid-template-columns: 1fr !important;
    }
}