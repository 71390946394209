#accountPage {
 
    .user-box {
        background: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .user-details { 
            border-bottom: 1px solid transparentize($color: #707070, $amount: 0.5);
            
            .user-image {
                width: 120px;
                height: 120px;
                object-fit: cover;
                border-radius: 100%;
                margin: auto;
                background-color: #ccc;
            }
        }
    }

    .account-link {
        background-color: #020504;
        transition: 0.3s;

        &:hover { opacity: 0.5 }
        &.active { background: #ED1A3A }
    }

    .account-content-wrapper {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #A4A4A480;
        background: #fff;
    }

    .account-settings-link {
        border-bottom: 1px solid transparentize($color: #707070, $amount: 0.5);
    }

    .profile-control-group {
        
        input {
            background-color: transparentize($color: #ED1A3A, $amount: 0.9);
            border: 1px solid #BABABA;
            box-shadow: none;
            width: 100%;
            border-radius: 3px;
            padding: 10px 15px;
            box-sizing: border-box;
            outline: none;
            font-size: 20px;
        }
    }
}

@media (max-width: 1400px){
    .account-grid {
        grid-template-columns: 1fr !important;
    }
}