#resourcesPage {

    .course-resources-wrapper {
        border: 1px solid #7070703B;
    
        .course-resource {
        
            .resource-icon {
                width: 30px;
    
                img {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }
            }
    
            &:nth-child(odd) { background: #F0F0F1 }
            &:nth-child(even) { background: #FBFBFB }
        }
    }

    .resource-config-box {
        background: #fff;
        border: #BCBCBC80;
        box-shadow: 0px 3px 6px #00000029;

        .config-group { border-top: 1px solid transparentize($color: #707070, $amount: 0.8); }
    }
    .break-word{
        word-break: break-all;
    }
}


@media (max-width: 1600px){
    .resources-grid {
        grid-template-columns: 2fr minmax(450px, 1fr) !important;
    }
}
@media (max-width: 1350px){
    .resources-grid {
        grid-template-columns: 1fr !important;
        & > div:first-child{
            order: 2;
        }
        & > div:last-child{
            order: 1;
        }
    }
}