@media (max-width: 767px) {
    .p-sm {

        &-5 {
            padding: 5px;
        }
    
        &-10 {
            padding: 10px;
        }
    
        &-15 {
            padding: 15px;
        }
    
        &-20 {
            padding: 20px;
        }
    
        &-25 {
            padding: 25px;
        }
        
        &-30 {
            padding: 30px;
        }
    
        &-35 {
            padding: 35px;
        }
    
        &-40 {
            padding: 40px;
        }
    
        &-45 {
            padding: 45px;
        }
    
        &-50 {
            padding: 50px;
        }
    }
    
    .pt-sm {
    
        &-5 {
            padding-top: 5px;
        }
    
        &-10 {
            padding-top: 10px;
        }
    
        &-15 {
            padding-top: 15px;
        }
    
        &-20 {
            padding-top: 20px;
        }
    
        &-25 {
            padding-top: 25px;
        }
        
        &-30 {
            padding-top: 30px;
        }
    
        &-35 {
            padding-top: 35px;
        }
    
        &-40 {
            padding-top: 40px;
        }
    
        &-45 {
            padding-top: 45px;
        }
    
        &-50 {
            padding-top: 50px;
        }
    }
    
    .pb-sm {
    
        &-5 {
            padding-bottom: 5px;
        }
    
        &-10 {
            padding-bottom: 10px;
        }
    
        &-15 {
            padding-bottom: 15px;
        }
    
        &-20 {
            padding-bottom: 20px;
        }
    
        &-25 {
            padding-bottom: 25px;
        }
        
        &-30 {
            padding-bottom: 30px;
        }
    
        &-35 {
            padding-bottom: 35px;
        }
    
        &-40 {
            padding-bottom: 40px;
        }
    
        &-45 {
            padding-bottom: 45px;
        }
    
        &-50 {
            padding-bottom: 50px;
        }
    }
    
    .pl-sm {
    
        &-5 {
            padding-left: 5px;
        }
    
        &-10 {
            padding-left: 10px;
        }
    
        &-15 {
            padding-left: 15px;
        }
    
        &-20 {
            padding-left: 20px;
        }
    
        &-25 {
            padding-left: 25px;
        }
        
        &-30 {
            padding-left: 30px;
        }
    
        &-35 {
            padding-left: 35px;
        }
    
        &-40 {
            padding-left: 40px;
        }
    
        &-45 {
            padding-left: 45px;
        }
    
        &-50 {
            padding-left: 50px;
        }
    }
    
    .pr-sm {
    
        &-5 {
            padding-right: 5px;
        }
    
        &-10 {
            padding-right: 10px;
        }
    
        &-15 {
            padding-right: 15px;
        }
    
        &-20 {
            padding-right: 20px;
        }
    
        &-25 {
            padding-right: 25px;
        }
        
        &-30 {
            padding-right: 30px;
        }
    
        &-35 {
            padding-right: 35px;
        }
    
        &-40 {
            padding-right: 40px;
        }
    
        &-45 {
            padding-right: 45px;
        }
    
        &-50 {
            padding-right: 50px;
        }
    }
    
    .py-sm {
    
        &-5 {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    
        &-10 {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    
        &-15 {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    
        &-20 {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    
        &-25 {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        
        &-30 {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    
        &-35 {
            padding-top: 35px;
            padding-bottom: 35px;
        }
    
        &-40 {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    
        &-45 {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    
        &-50 {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    
    .px-sm {
    
        &-5 {
            padding-left: 5px;
            padding-right: 5px;
        }
    
        &-10 {
            padding-left: 10px;
            padding-right: 10px;
        }
    
        &-15 {
            padding-left: 15px;
            padding-right: 15px;
        }
    
        &-20 {
            padding-left: 20px;
            padding-right: 20px;
        }
    
        &-25 {
            padding-left: 25px;
            padding-right: 25px;
        }
        
        &-30 {
            padding-left: 30px;
            padding-right: 30px;
        }
    
        &-35 {
            padding-left: 35px;
            padding-right: 35px;
        }
    
        &-40 {
            padding-left: 40px;
            padding-right: 40px;
        }
    
        &-45 {
            padding-left: 45px;
            padding-right: 45px;
        }
    
        &-50 {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}