#reportsWrapper {

    .reports-table {
        border: 1px solid #A4A4A480;
        
        .at-row {
            display: grid;
            grid-template-columns: repeat(4, minmax(100px, 1fr));
            align-items: center;

            &:nth-child(odd):not(.bg-black) { background: #F2F2F2; }
        }
    }
}

@media (max-width: 1400px){
    #reportsWrapper .reports-table .at-row {
        grid-template-columns: minmax(0, 90px) 1fr 1fr 1fr;
    }
}